import React, {
} from "react";
import PropTypes from 'prop-types';

import {
  ref,
  remove,
} from 'firebase/database';

import {
  useDatabase,
  useDatabaseListData,
} from 'reactfire';

import {
  Container,
  Grid,
} from '@material-ui/core';

import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import LoadingPage from './LoadingPage';
import LogList from "../components/LogList";
import CashDisplay from "../components/CashDisplay";

// TODO: If you sign in with a new user and visit the log page, then log a poop, the log page will not update
const LogLogPage = ({ user }) => {
  const { uid } = user;
  const db = useDatabase();

  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const wageRef = ref(db, `users/${uid}/log`);
  const { status, data: log } = useDatabaseListData(wageRef);

  const deleteEntry = ({NO_ID_FIELD : id}) => {
    confirm({
      title: 'Are you sure?',
      description: `Would you like to delete this record from the Log Log?`,
      confirmationText: 'Delete it!',
      cancellationText: 'Oops! Nevermind',
      confirmationButtonProps: { variant: 'contained', autoFocus: true }
    })
      .then(() => {
        remove(ref(db, `users/${uid}/log/${id}`));
      })
      .then(() => enqueueSnackbar('Record deleted!'))
      .catch(() => {
      });
  };

  if (status === "loading") {
    return (<LoadingPage />);
  }

  const computeTotal = (log) => {
    return log.map((item) => parseFloat(item.amount)).reduce((a, b) => a + b, 0)
  };

  return (
    <Container>
      <Grid container direction={'column'} spacing={4} alignContent={'center'}>
        <Grid item>
          <CashDisplay amount={computeTotal(log)} heading={'Total Earnings:'} />
        </Grid>
        <Grid item>
          { log.length === 0 ?
            "Nothing to see here." :
            <LogList entries={log} deleteEntry={deleteEntry} />
          }
        </Grid>
      </Grid>
    </Container>
  );
};

LogLogPage.propTypes = {
  onClick: PropTypes.arrayOf({
    amount: PropTypes.number.isRequired,
    timestamp: PropTypes.number.isRequired,
  }),
};

LogLogPage.defaultProps = {
  entries: [],
};

export default LogLogPage;