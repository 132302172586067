import React, {
} from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const LogList = ({ entries, deleteEntry }) => {
  return (
    <Paper>
      <List dense>
        {entries.map((entry, idx) => (
          <ListItem key={idx} button>
            <ListItemText
              primary={
                `$${(entry.amount).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}`
              }
              secondary={`${new Date(entry.timestamp).toUTCString()}`}
            />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => deleteEntry(entry)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

LogList.propTypes = {
  onClick: PropTypes.arrayOf({
    amount: PropTypes.number.isRequired,
    timestamp: PropTypes.number.isRequired,
  }),
};

LogList.defaultProps = {
  entries: [],
};

export default LogList;