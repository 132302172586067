import React, {
  useEffect,
  useState,
} from "react";
import PropTypes from 'prop-types';

import {
  ref,
  set,
  push,
} from 'firebase/database'
import {
  useDatabase,
  useDatabaseObjectData,
} from 'reactfire';

import {
  Container,
  Grid,
} from '@material-ui/core';

import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import {
  DEFAULT_WAGE,
} from "../config/constants";

import LoadingPage from "./LoadingPage";
import CashDisplay from "../components/CashDisplay";
import StartPoopinButton from "../components/StartPoopinButton";

const SplashPage = ({ user }) => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const [timeElapsed, setTimeElapsed] = useState(0);
  const [isPoopin, setIsPoopin] = useState(false);

  const { uid } = user;
  const db = useDatabase();

  const wageRef = ref(db, `users/${uid}/wage`);
  const { status, data: wage } = useDatabaseObjectData(wageRef);

  const getDisplayAmmount = () => {
    return (salary / 100 * timeElapsed / 60 / 60) || 0;
  };

  const logPoop = (amount) => {
    const logRef = ref(db, `users/${uid}/log`);
    const newLogRef = push(logRef);

    set(newLogRef, { "amount": amount, timestamp: Date.now() })
      .then(enqueueSnackbar(`Your $${amount} poop was saved to the Log Log!`))
      .then(setTimeElapsed(0));
  };

  const handlePoopStopped = () => {
    if (status !== "loading" && timeElapsed !== 0) {
      confirm({
        title: 'Save?',
        description: `Would you like save your $${getDisplayAmmount().toFixed(2)} poop to the Log Log?`,
        confirmationText: 'Log it!',
        cancellationText: 'Drop it!',
        confirmationButtonProps: { variant: 'contained', autoFocus: true }
      })
        .then(() => {
          logPoop(getDisplayAmmount().toFixed(2));
        })
        .catch(() => {
          setTimeElapsed(0)
        });
    }
  };

  useEffect(() => {
    let interval = null;
    let STEP = 1000;

    if (isPoopin) {
      interval = setInterval(() => {
        setTimeElapsed((timeElapsed) => timeElapsed + STEP / 1000);
      }, STEP);
    } else {
      clearInterval(interval);
      handlePoopStopped();
    }

    return () => clearInterval(interval); // Unmount behavior
  }, [isPoopin, handlePoopStopped]);

  if (status === "success" && !wage) {
    set(wageRef, DEFAULT_WAGE);
  }

  if (status === "loading" || !wage) {
    return (<LoadingPage />);
  }

  const salary = wage.salaryType === "hourly" ? wage.salary : wage.salary / 40 / 52;



  return (
    <Container>
      <Grid container direction={'column'} spacing={4} alignContent={'center'}>
        <Grid item>
          <CashDisplay amount={getDisplayAmmount()} />
        </Grid>
        <Grid item>
          <StartPoopinButton
            onClick={setIsPoopin}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

SplashPage.propTypes = {
  wage: PropTypes.number,
};

SplashPage.defaultProps = {
  wage: 15,
};

export default SplashPage;