import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'table',
    padding: theme.spacing(1),
    margin: 'auto',
  },
  salary: {
    width: 100,
  },
  cash: {
    maxWidth: 300,
  }
}));

const SalaryInput = ({ disabled, salary, setSalary }) => {
  const classes = useStyles();

  const updateSalary = (key) => {
    if (key >= "0" && key <= "9") setSalary(salary * 10 + parseInt(key));
    if (key === "Backspace") setSalary((salary - (salary % 10)) / 10);
  };

  return (
    <FormControl className={classes.salary} disabled={disabled}>
      <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
      <Input
        id="standard-adornment-amount"
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        value={(salary / 100).toLocaleString('en-US', {
          style: 'decimal',
          currency: 'USD',
          minimumFractionDigits: 2,
        })}
        onKeyDown={(e) => updateSalary(e.key)}
      />
    </FormControl>
  );
};

const SalaryTypeInput = ({ disabled, salaryType, setSalaryType }) => {
  return (
    <FormControl component="fieldset" disabled={disabled}>
      <FormLabel component="legend">Salary Type</FormLabel>
      <RadioGroup
        aria-label="salary-type"
        name="salary-type"
        value={salaryType}
        onChange={(e) => setSalaryType(e.target.value)}
      >
        <FormControlLabel value="hourly" control={<Radio />} label="Hourly" />
        <FormControlLabel value="annually" control={<Radio />} label="Annually" />
      </RadioGroup>
    </FormControl>
  );
};

const PlumbingPanel = ({ loading, salary=1500, setSalary, salaryType="hourly", setSalaryType }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid
        container
        direction={'column'}
        spacing={4}
        alignContent={'center'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid item>
          <Typography
            className={classes.cash}
            variant={'h4'}
            align={'center'}
          >
            How much 💰💰💰 do you make?
          </Typography>
        </Grid>
        <Grid item>
          <SalaryInput
            salary={salary}
            setSalary={setSalary}
            disabled={loading}
          />
        </Grid>
        <Grid item>
          <SalaryTypeInput
            salaryType={salaryType}
            setSalaryType={setSalaryType}
            disabled={loading}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

PlumbingPanel.propTypes = {
  loading: PropTypes.bool,
  salary: PropTypes.number,
  setSalary: PropTypes.func,
  salaryType: PropTypes.string,
  setSalaryType: PropTypes.func,
};

PlumbingPanel.defaultProps = {
  lading: false,
};

export default PlumbingPanel;