import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    margin: 'auto',
    display: 'block',
  },
});

const StartPoopinButton = ({ onClick }) => {
  const classes = useStyles();
  const [started, setStarted] = useState(false);

  return (
    <Button
      className={classes.root}
      color={!started ? "primary" : "secondary"}
      variant={"contained"}
      onClick={() => {
        onClick(!started);
        setStarted(!started);
      }}
    >
      {!started ? 'Start Poopin\'!' : 'Stop Poopin\'!'}
    </Button>
  );
};

StartPoopinButton.propTypes = {
  onClick: PropTypes.func,
};

StartPoopinButton.defaultProps = {
};

export default StartPoopinButton;