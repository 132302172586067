import React, {} from 'react';
import PropTypes from 'prop-types';

import {
  Paper, Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'table',
    padding: theme.spacing(1),
    margin: 'auto',
  },
  cash: {
    color: '#66bb6a',
    fontSize: 42,
    fontWeight: 'bold',
  },
}));

const CashDisplay = ({amount, heading}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      { heading && <Typography className={classes.cash} align={'center'}>{`${heading}`}</Typography>}
      <Typography className={classes.cash} align={'center'}>{`$${amount.toFixed(2)}`}</Typography>
    </Paper>
  );
};

CashDisplay.propTypes = {
  amount: PropTypes.number.isRequired,
  heading: PropTypes.string,
};

CashDisplay.defaultProps = {
  ammount: 0,
};

export default CashDisplay;