export const firebaseConfig = {
  apiKey: "AIzaSyDrGNBMqxs_P-qOFlcYNMc0HSDqOtDhdf8",
  authDomain: "my-project-1474228297853.firebaseapp.com",
  databaseURL: "https://my-project-1474228297853.firebaseio.com",
  projectId: "my-project-1474228297853",
  storageBucket: "my-project-1474228297853.appspot.com",
  messagingSenderId: "473857909716",
  appId: "1:473857909716:web:b084218ff6821d2805ac41"
};

const DEFAULT_EMULATOR_DATABASE = 'localhost';
export const EMULATOR_DATABASE = process.env.REACT_APP_EMULATOR_DATABASE || process.env.EMULATOR_DATABASE || DEFAULT_EMULATOR_DATABASE;
const DEFAULT_EMULATOR_DATABASE_PORT = 9000;
export const EMULATOR_DATABASE_PORT = process.env.REACT_APP_EMULATOR_DATABASE_PORT || process.env.EMULATOR_DATABASE_PORT || DEFAULT_EMULATOR_DATABASE_PORT;

const DEFAULT_EMULATOR_AUTH = 'http://localhost:9099';
export const EMULATOR_AUTH = process.env.REACT_APP_EMULATOR_AUTH || process.env.EMULATOR_AUTH || DEFAULT_EMULATOR_AUTH;

export const DEFAULT_WAGE = {
  salary: 1500,
  salaryType: "hourly",
};