import React, { } from "react";

import {
  getAuth,
  signInAnonymously,
} from "firebase/auth";
import {
  useSigninCheck,
} from 'reactfire';

const AuthButton = () => {
  const { status, data: signInCheckResult } = useSigninCheck();
  const auth = getAuth();

  if (status === "success" && !signInCheckResult.signedIn) {
    signInAnonymously(auth)
      .then(() => {
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <></>
  );
};

export default AuthButton;