import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import {
  FirebaseAppProvider,
  AuthProvider,
  DatabaseProvider,
  useFirebaseApp,
} from 'reactfire';
import {
  getAuth,
  connectAuthEmulator,
} from 'firebase/auth';
import {
  getDatabase,
  connectDatabaseEmulator,
} from 'firebase/database';

import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';

import { ThemeProvider } from '@material-ui/styles';
import mainTheme from '../src/themes/mainTheme';

import {
  EMULATOR_AUTH,
  EMULATOR_DATABASE,
  EMULATOR_DATABASE_PORT,
  firebaseConfig,
} from './config/constants';

import App from './App';

const FirebaseComponents = ({ children }) => {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const db = getDatabase(app);

  if (process.env.NODE_ENV !== 'production') {
    connectAuthEmulator(auth, EMULATOR_AUTH);
    connectDatabaseEmulator(db, EMULATOR_DATABASE, EMULATOR_DATABASE_PORT);
  }

  return (
    <AuthProvider sdk={auth}>
      <DatabaseProvider sdk={db}>
        {children}
      </DatabaseProvider>
    </AuthProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseComponents>
        <ThemeProvider theme={mainTheme}>
          <ConfirmProvider>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </ConfirmProvider>
        </ThemeProvider>
      </FirebaseComponents>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
