import React, {
} from "react";

import {
  Container,
} from '@material-ui/core';

import {
  ref,
  set,
} from 'firebase/database';

import {
  useDatabase,
  useDatabaseObjectData,
} from 'reactfire';

import { 
  DEFAULT_WAGE,
} from "../config/constants";

import LoadingPage from "./LoadingPage";
import PlumbingPanel from "../components/PlumbingPanel";

const SplashPage = ({ user }) => {
  const { uid } = user;
  const db = useDatabase();

  const wageRef = ref(db, `users/${uid}/wage`);
  const { status, data: wage } = useDatabaseObjectData(wageRef);

  if (status === "success" && !wage) {
    set(wageRef, DEFAULT_WAGE);
  }

  if (status === "loading" || !wage) {
    return (<LoadingPage />);
  }

  const setSalary = (salary) => {
    set(wageRef, { salaryType: wage.salaryType, salary: salary })
  }

  const setSalaryType = (salaryType) => {
    set(wageRef, { salary: wage.salary, salaryType: salaryType })
  }

  return (
    <Container>
      <PlumbingPanel
        loading={!wage}
        setSalary={setSalary}
        setSalaryType={setSalaryType}
        salary={wage?.salary}
        salaryType={wage?.salaryType}
      />
    </Container>
  );
};

export default SplashPage;