import { LinearProgress } from '@material-ui/core';
import React, {} from 'react';

import {
  useUser,
} from 'reactfire';

import TabLayout from '../components/TabLayout';
import LogLogPage from './LogLogPage';
import PlumbingPage from './PlumbingPage';
import SplashPage from './SplashPage';

const MainPageLayout = () => {  
  const { status, data: user } = useUser();

  // The user will be automatically set to anonymous in the parent component
  if(status !== "success" || !user) return(<LinearProgress/>);

  const tabs = [
    { tabText: "Splash", tabContent:  <SplashPage user={user} /> },
    { tabText: "Plumbing", tabContent: <PlumbingPage user={user} /> },
    { tabText: "The Log Log", tabContent: <LogLogPage user={user} /> },
  ];

  return (
    <TabLayout tabs={tabs} tabBarStyles={{color: '#fff'}}/>
  );
};

MainPageLayout.propTypes = {
};

MainPageLayout.defaultProps = {
};

export default MainPageLayout;