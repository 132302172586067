import {
  createTheme,
  responsiveFontSizes
} from '@material-ui/core/styles';
import {
  pink,
  lightBlue
} from '@material-ui/core/colors';

const mainTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: lightBlue[500],
    },
    secondary: {
      main: pink[200]
    },
  },
});

export default responsiveFontSizes(mainTheme);