import React, { Suspense } from "react";

import {
  AppBar,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MainPageLayout from "./pages/MainPageLayout";
import AuthButton from "./components/AuthButton";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  title: {
    flexGrow: 1,
    color: '#fff',
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title}>Cash While You Splash!</Typography>
          <AuthButton />
        </Toolbar>
      </AppBar>
      <div>
        <Suspense>
          <MainPageLayout />
        </Suspense>
      </div>
    </div>
  );
}

export default App;